import SocketInterface from './socketInterface'

const USER = 'appuser';
const PASSWORD = 'FrontApp#Pass&';
const EVENT = 'message';
class MachineInterfaceClass extends SocketInterface {
    static CODES = {
        OK: 0,
        ERROR_PARAMETERS: 1,
        PERFUME_DISABLED: 2,
        ERROR_EXECUTION: 3,
        SPRAY_OK: 4,
        API_DISABLED: 5,
        EXE_MAXTIMEREACHED: 6,
        WAIT_MAXTIMEREACHED: 7,
        OK_CONNECTMACH: 8,
        ERROR_CONNECTMACH: 9,
        SPARY_DISABLED: 10,
    }
    initSession() {
        console.log('init Session')
        console.log('EMIT;', `Event name = ${EVENT}`, {
            typeOp: 'initSession'
        })
        this.socket.emit(EVENT, {
            user: USER,
            password: PASSWORD,
            typeOp: 'initSession'
        })
    }

    endSession() {
        console.log('end Session')
        console.log('EMIT;', `Event name = ${EVENT}`, {
            typeOp: 'endSession'
        })
        this.socket.emit(EVENT, {
            user: USER,
            password: PASSWORD,
            typeOp: 'endSession'
        })
    }
    /**
     * 
     * @param {(res:{response:number,comment:string})=>void} callback 
     */
    listen(callback) {
        return this.subscribe(EVENT, (data) => callback(data))
    }

    /**
     * 
     * @param {*} data 
     * @param {(err,data)=>void} callback 
     */
    sendStart(data) {
        console.log('EMIT;', `Event name = ${EVENT}`, {parameters:data.parameters,typeOp:data.typeOp})
        this.socket.emit(EVENT, data)
    }
    startMix(cartridges) {
        const data = {
            user: 'appuser',
            password: 'FrontApp#Pass&',
            typeOp: 'startPerfumeMixGroupPerf',
            parameters: {
                list: cartridges.map((cartridge) => {
                    return {
                        EAN: cartridge.EAN,
                        percMix: cartridge.PercMix,
                        group: cartridge.Group
                    }
                }),
                power: 6,
                // group: 1,
                testMode: 0
            }
        }
        return this.sendStart(data)
    }
    /**
     * 
     * @param {{EAN:string,group?:number,testMode?:number}} cartridge 
     */
    startSingle(cartridge) {
        const data = {
            user: 'appuser',
            password: 'FrontApp#Pass&',
            typeOp: 'startPerfume',
            parameters: {
                EAN: cartridge.EAN,
                group: cartridge.group ?? 0,
                power: 6,
                testMode: cartridge.testMode ?? 0
            }
        }
        return this.sendStart(data)
    }
    stopPerfume() {
        const data = {
            user: USER,
            password: PASSWORD,
            typeOp: 'stopPerfume'
        }
        return this.sendStart(data)
    }
}

const url =  window.location.protocol === 'http:' ? 'http://airparfum-machine.com:3000' : 'https://airparfum-machine.com:3001'
const createMachineInstance = () => new MachineInterfaceClass(url)

let machineInstance;
const getMachineInstance = () => {
    if(machineInstance) {
        return machineInstance;
    }
    machineInstance = createMachineInstance();
    return machineInstance
}

export { getMachineInstance }
export default  MachineInterfaceClass
