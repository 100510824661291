import io from 'socket.io-client'
class SocketInterface {
    /** @type {{[event:string]:((data)=>void)[]}} */
    callbacks = {}
    constructor(apiUrl) {
        this.socket = io(apiUrl, {
            secure: false
        })        
    }
    getCallbacks(name){
        return this.callbacks[name] ? this.callbacks[name] : []
    }
    startListener(event){
        console.log('subscribe on',event)
        this.socket.on(event,(data)=>{
            console.log(`Event name = ${event} \n`,data)
            this.getCallbacks(event).forEach(cb => cb(data))
            
        })
    }
    stopListener(event){
        this.socket.off(event)
    }
    /**
     * 
     * @param {string} name Event Name
     * @param {(data)=>void} callback 
     * @returns {()=>void} unsuscribe function
     */
    subscribe(name,callback){
        // we can improve if this.callbacks[name].length === 0 then stopListener
        if(this.callbacks[name]){
            //this.callbacks[name].push(callback)
            this.callbacks[name] = [callback]
        }
        else{
            this.callbacks[name] = [callback]
            this.startListener(name)
        }
        return ()=>{
            this.callbacks[name].slice(this.callbacks[name].indexOf(callback),1)
        }
    }
    unsubscribe(name) {
        this.callbacks[name] = null
        this.socket.off(name)
    }


}
export default SocketInterface